(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("photoswipe"), require("photoswipe/dist/photoswipe-ui-default"));
	else if(typeof define === 'function' && define.amd)
		define("NaturalGallery", ["photoswipe", "photoswipe/dist/photoswipe-ui-default"], factory);
	else if(typeof exports === 'object')
		exports["NaturalGallery"] = factory(require("photoswipe"), require("photoswipe/dist/photoswipe-ui-default"));
	else
		root["NaturalGallery"] = factory(root["PhotoSwipe"], root["PhotoSwipeUI_Default"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__104__, __WEBPACK_EXTERNAL_MODULE__105__) {
return 